// Import React
import React from 'react';

// Import gatsby
import {navigate} from "gatsby"

// Import Firebase
// import getFirebase from '../utils/firebase';

// Import Material UI
import Grow from '@material-ui/core/Grow';
// import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
// import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import Dialog from '@material-ui/core/Dialog';
// import TextField from '@material-ui/core/TextField';
// import VpnKeyIcon from '@material-ui/icons/VpnKey';
// import FormControl from '@material-ui/core/FormControl';
// import FormHelperText from '@material-ui/core/FormHelperText'
// import Input from '@material-ui/core/Input'
// import InputLabel from '@material-ui/core/InputLabel';
// import PostAddIcon from '@material-ui/icons/PostAdd';

// Import components
import Layout from "../components/layout";
import SEO from "../components/seo"

import MenuAppBar from '../components/AppBar'

// import SendIcon from '@material-ui/icons/Send';

// Make Styles
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '',
    height: '98vh',
    marginTop: '1vh',
    width: '99vw',
    padding: '5vh 8vw'
  },
  title: {
    textAlign: 'center',
    fontWeight: '700',
    fontFamily: 'Work Sans',
    padding: '0px 20px',
    fontSize: '4vw',
    lineHeight: '1.1',
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      fontSize: '6vw',
      padding: '20px'
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      fontSize: '11vw',
      padding: '20px'
    }
  },
  description: {
    fontFamily: 'Work Sans',
    textAlign: 'center',
    fontWeight: '300',
    lineHeight: '1.1',
    fontSize: '2.8vw',
    // padding: '0.5vw 3vw',
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      fontSize: '3.5vw',
      padding: '0.5vw 2vw',
      // padding: '5px 5px'
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      fontSize: '5.5vw',
      padding: '1vw 3vw'
    }
  },
  credit: {
    fontFamily: 'Work Sans',
    textAlign: 'center',
    fontWeight: '200',
    lineHeight: '1.3',
    fontSize: '2vw',
    padding: '12px 20px 5px',
    color: '#6e6e6e',
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      fontSize: '3vw'
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      fontSize: '4.5vw'
    }
  },
  dialog: {
    padding: '40px',
    textAlign: 'center',
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      padding: '20px'
    }
  },
  button: {
    margin: '8px',
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      margin: '5px'
    }
  },
  featuresContainer: {
    backgroundColor: '',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px 20px',
    paddingTop: '40px',
    width: '400px',
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      paddingTop: '20px'
    }
  },
  featureDescriptionText: {
    fontSize: '20px',
    fontWeight: '300',
    backgroundColor: '',
    textAlign: 'center',
    // paddingLeft: '20px',
    margin: '0px',
    padding: '5px',
    // color: '#545454',
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      fontSize: '22px'
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      fontSize: '4.8vw'
    },
  },
  featureText: {
    fontSize: '28px',
    fontWeight: '500',
    backgroundColor: '',
    textAlign: 'center',
    margin: '0px',
    // color: '#545454',
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      fontSize: '28px'
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      fontSize: '4.8vw'
    },
    "&:hover": {
      color: '#121212',
    }
  }
}))

// Transitions
// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction='up' ref={ref} {...props}/>;
// });

// Page
function SignedIn() {

  // Use Styles
  const classes = useStyles();

  // State
  // const [activeUser, setActiveUser] = useState(undefined);
  // const [greeting, setGreeting] = useState('');
  // const [photoURL, setPhotoURL] = useState(undefined);
  // const [isLoaded, setIsLoaded] = useState(false);
  // const [dialogIsOpen, setDialogIsOpen] = useState(false);

  // Get Firebase
  // const firebase = getFirebase();

  // Effect Hook
  //
  // useEffect(() => {
  //   if (!firebase)
  //     return;
  //   firebase
  //     .auth()
  //     .onAuthStateChanged((user) => {
  //       if (user) {
  //          alert('User is signed in')
  //          setIsSignedIn(!!user);
  //         setActiveUser(user)
  //         const db = firebase.firestore();
  //         let docRef = db
  //           .collection("users")
  //           .doc(user.uid);
  //         docRef
  //           .get()
  //           .then(function(doc) {
  //             if (doc.exists) {
  //                setPhotoURL(activeUser.photoURL);
  //                setGreeting(`${activeUser.displayName}`);
  //               setIsLoaded(true);
  //             } else {
  //                doc.data() will be undefined in this case
  //               navigate('/signedin')
  //             }
  //           })
  //           .catch(function(error) {
  //             console.log("Error getting document:", error);
  //           });
  //       } else {
  //         navigate('/')
  //       }
  //     });
  // });

  // Functions
  // const handleSignOut = () => {
  //   firebase
  //     .auth()
  //     .signOut();
  //   navigate('/signedout')
  // };
  // const openUnlockDialog = () => {
  //   setDialogIsOpen(true);
  // };
  // const closeDialog = value => {
  //   setDialogIsOpen(false);
  // };

  function goToFavourites() {
    navigate('/favourites')
  }

  // Conditionals
  // const profileImage = (
  //   photoURL
  //   ? <img src={photoURL} alt='Profile' width='150px' height='150px' style={{
  //       borderRadius: '150px',
  //       marginBottom: '-2px'
  //     }}/>
  //   : undefined)

  // Components
  const body = (<Grid item="item">
    <Typography variant='h3' className={classes.featureText}>Coming Soon</Typography>
    <Typography className={classes.title}>Share Your Own Recipes</Typography>
    <Typography className={classes.featureDescriptionText}>Stay tuned for new features.</Typography>
  </Grid>)
  const buttons = (<Grid item="item">
    <Grid container="container" justify="space-evenly" alignItems="center">
      <Button size='large' color="secondary" variant='contained' onClick={goToFavourites} className={classes.button}>View My Favourites</Button>
    </Grid>
  </Grid>)

  // Final Page
  // const loading = <Grid container="container" direction="column" justify="center" alignItems="center" className={classes.root}>
  //   <CircularProgress/>
  // </Grid>

  const loadedPage = <Grid container="container" className={classes.root}>
    <Grid container="container" direction="column" justify="center" alignItems="center" spacing={3}>
      {body}
      {buttons}
    </Grid>
  </Grid>

  // Render
  return (<Layout>
    <SEO title="My Recipes"/>
    <MenuAppBar/>
    <Grow in="in">
      {loadedPage}
    </Grow>
  </Layout>)
}

export default SignedIn
